/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";

export default function Arrow(props) {
  const { onClick, id, type } = props;
  return (
    <div
      className={`${type} absolute w-20 cursor-pointer z-10`}
      onClick={onClick}
      role="button"
      aria-label="arrow button"
      tabIndex="0"
    >
      <svg viewBox="0 0 44.11 25">
        <clipPath id="arrow-up">
          <rect
            className="arrow-rect"
            x="3.66"
            width="36"
            height="55"
            transform="translate(43.32 55) rotate(180)"
          />
        </clipPath>
        <g style={{ clipPath: `url(#${id})` }}>
          <polyline
            className="arrow-polyline"
            points="42.1 17.33 22.05 3.29 2.01 17.33"
          />
        </g>
      </svg>
    </div>
  );
}
