import React, { Component } from 'react';
import { Link } from 'gatsby';
import * as styles from './index.module.scss';
import SectionTitle from '../../utils/SectionTitle';
import bars from '../../../images/logo/slantedbars.svg';
import ReadMoreButton from '../../utils/Button/ReadMoreButton';
import Slider from './Slider';
import { homeSections } from '../../utils/helperFunctions';

export default class Portfolio extends Component {

	render() {
		const { activeSection } = this.props;

		return (
			<section className="h-full">
				<div
					className={`${styles.whiteBg} absolute hidden top-0 left-0 bg-white opacity-50 h-full w-full lg:block`}
				/>
				<SectionTitle
					section="portfolio"
					isVisible={activeSection === homeSections.PORTFOLIO}
					color="brand-white"
				>
					Portfolio
				</SectionTitle>
				<div className={`${styles.bars} absolute lg:hidden`}>
					<img src={bars} alt="bars graphics"/>
				</div>

				<Slider />
				<div className={`${styles.btnContainer} absolute`}>
					<Link to="/portfolio">
						<ReadMoreButton type="primary" color="black">
							View All
						</ReadMoreButton>
					</Link>
				</div>
			</section>
		);
	}
}
