import React, { Component } from "react";
import * as styles from "./index.module.scss";
import { TweenMax, TimelineMax, Power0, Sine } from "gsap";
import BackgroundImage from "gatsby-background-image";
import Player from "@vimeo/player";
export default class Animation extends Component {
  constructor() {
    super();

    this.video = null;
    this.videoContainer = null;
    this.videobg = null;
    this.state = {
      videoIsReady: false,
    };
  }

  componentDidMount() {
    if (typeof window !== "undefined" && window.innerWidth > 1024) {
      this.setState({ videoIsReady: true });

      const player = new Player(this.video, {
        id: 387965410,
        background: true,
        responsive: true,
        autoplay: true,
        muted: true,
        loop: false,
      });

      player.on("ended", () => {
        TweenMax.to(this.video, 1, { autoAlpha: 0, ease: Sine.easeOut });
        TweenMax.to(this.videobg, 1, { autoAlpha: 1, ease: Sine.easeOut });
      });
    } else {
      this.setState({ videoIsReady: true });
      TweenMax.to(this.video, 1, { autoAlpha: 0, ease: Sine.easeOut });
      TweenMax.to(this.videobg, 1, { autoAlpha: 1, ease: Sine.easeOut });
    }
  }

  render() {
    return (
      <>
        <div
          className={styles.videoContainer}
          ref={(video) => (this.videoContainer = video)}
        >
          <div className={styles.blackBg}></div>
          <div
            className={styles.videoBg}
            ref={(videobg) => (this.videobg = videobg)}
          >
            {/* <Img fluid={this.props.img} className="w-full h-full md:hidden"/> */}
            <BackgroundImage
              Tag="div"
              className={styles.videoBg}
              fluid={this.props.img}
              backgroundColor={`#040e18`}
            />
          </div>
          <div
            className={styles.video}
            id="video"
            ref={(video) => (this.video = video)}
          />
        </div>
        <LogoAnimation videoIsReady={this.state.videoIsReady} />
      </>
    );
  }
}

class LogoAnimation extends Component {
  constructor() {
    super();

    this.line1 = null;
    this.line2 = null;
    this.line3 = null;
    this.line4 = null;
    this.line5 = null;
    this.line6 = null;
    this.line7 = null;
    this.line8 = null;
    this.line9 = null;
    this.line10 = null;
    this.line11 = null;
    this.line12 = null;
    this.line13 = null;
    this.line14 = null;
    this.line15 = null;
    this.line16 = null;
    this.line17 = null;
    this.line18 = null;
    this.logo = null;
    this.logo1 = null;
    this.logo2 = null;
    this.logo3 = null;
    this.logoFill1 = null;
    this.logoFill2 = null;
    this.logoFill3 = null;
    this.lines = null;
    this.vectr = null;
    this.tl = null;
    this.logoTL = null;
    this.btn = null;
    this.graphics = null;
    this.logoContainer = null;
  }
  componentDidMount() {
    TweenMax.set(this.vectr, { autoAlpha: 0 });

    if (typeof window !== "undefined") {
      require("../../../../assets/js/DrawSVGPlugin");

      this.logoTL = new TimelineMax({ paused: true })
        .set(this.logo3, { autoAlpha: 1 })
        .set(this.logo1, { autoAlpha: 1 })
        .set(this.logo2, { autoAlpha: 1 })
        .to(this.logoContainer, 1.5, {
          autoAlpha: 0,
          ease: Sine.easeOut,
        })
        .fromTo(
          this.logo3,
          0.7,
          { drawSVG: "0" },
          { drawSVG: "100%", ease: Power0.easeOut },
          "-=1"
        )
        .to(this.logoFill3, 0.7, {
          autoAlpha: 1,
          fill: "#fff",
          ease: Power0.easeOut,
        })
        .fromTo(
          this.logo2,
          0.7,
          { drawSVG: "0" },
          { drawSVG: "100%", ease: Power0.easeOut },
          "-=1"
        )
        .to(this.logoFill2, 0.7, {
          autoAlpha: 1,
          fill: "#fff",
          ease: Power0.easeOut,
        })
        .fromTo(
          this.logo1,
          0.7,
          { drawSVG: "0" },
          { drawSVG: "100%", ease: Power0.easeOut },
          "-=1"
        )
        .to(this.logoFill1, 0.7, {
          autoAlpha: 1,
          fill: "#fff",
          ease: Power0.easeOut,
        })
        .fromTo(
          this.line1,
          0.8,
          { autoAlpha: 1 },
          { autoAlpha: 0, ease: Sine.easeOut }
        )
        .fromTo(
          this.line2,
          0.8,
          { autoAlpha: 1 },
          { autoAlpha: 0, ease: Sine.easeOut },
          "-=0.8"
        )
        .fromTo(
          this.line3,
          0.8,
          { autoAlpha: 1 },
          { autoAlpha: 0, ease: Sine.easeOut },
          "-=0.8"
        )
        .fromTo(
          this.line5,
          0.8,
          { autoAlpha: 1 },
          { autoAlpha: 0, ease: Sine.easeOut },
          "-=0.8"
        )
        .fromTo(
          this.line6,
          0.8,
          { autoAlpha: 1 },
          { autoAlpha: 0, ease: Sine.easeOut },
          "-=0.8"
        )
        .fromTo(
          this.line7,
          0.8,
          { autoAlpha: 1 },
          { autoAlpha: 0, ease: Sine.easeOut },
          "-=0.8"
        )
        .fromTo(
          this.line8,
          0.8,
          { autoAlpha: 1 },
          { autoAlpha: 0, ease: Sine.easeOut },
          "-=0.8"
        )
        .fromTo(
          this.line9,
          0.8,
          { autoAlpha: 1 },
          { autoAlpha: 0, ease: Sine.easeOut },
          "-=0.8"
        )
        .fromTo(
          this.line10,
          0.8,
          { autoAlpha: 1 },
          { autoAlpha: 0, ease: Sine.easeOut },
          "-=0.8"
        )
        .fromTo(
          this.line11,
          0.8,
          { autoAlpha: 1 },
          { autoAlpha: 0, ease: Sine.easeOut },
          "-=0.8"
        )
        .fromTo(
          this.line12,
          0.8,
          { autoAlpha: 1 },
          { autoAlpha: 0, ease: Sine.easeOut },
          "-=0.8"
        )
        .fromTo(
          this.line13,
          0.8,
          { autoAlpha: 1 },
          { autoAlpha: 0, ease: Sine.easeOut },
          "-=0.8"
        )
        .fromTo(
          this.line14,
          0.8,
          { autoAlpha: 1 },
          { autoAlpha: 0, ease: Sine.easeOut },
          "-=0.8"
        )
        .fromTo(
          this.line15,
          0.8,
          { autoAlpha: 1 },
          { autoAlpha: 0, ease: Sine.easeOut },
          "-=0.8"
        )
        .fromTo(
          this.line16,
          0.8,
          { autoAlpha: 1 },
          { autoAlpha: 0, ease: Sine.easeOut },
          "-=0.8"
        )
        .fromTo(
          this.line17,
          0.8,
          { autoAlpha: 1 },
          { autoAlpha: 0, ease: Sine.easeOut },
          "-=0.8"
        )
        .fromTo(
          this.line18,
          0.8,
          { autoAlpha: 1 },
          {
            autoAlpha: 0,
            ease: Sine.easeOut,
            onComplete: () => {
              if (this.logo) {
                this.logo.classList.add(styles.animate);
              }
            },
          },
          "-=0.8"
        )
        .to(this.vectr, 1.2, { autoAlpha: 1, ease: Sine.easeOut }, "+=1")
        .to(
          this.logoContainer,
          1.5,
          {
            autoAlpha: 0,
            ease: Sine.easeOut,
          },
          "-=2"
        )
        .delay(2.2);

      this.tl = new TimelineMax({ paused: true })
        .set(this.lines, { autoAlpha: 1 })
        .fromTo(
          this.line1,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut }
        )
        .fromTo(
          this.line2,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .fromTo(
          this.line3,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .fromTo(
          this.line5,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .fromTo(
          this.line6,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .fromTo(
          this.line7,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .fromTo(
          this.line8,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .fromTo(
          this.line9,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .fromTo(
          this.line10,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .fromTo(
          this.line11,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .fromTo(
          this.line12,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .fromTo(
          this.line13,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .fromTo(
          this.line14,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .fromTo(
          this.line15,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .fromTo(
          this.line16,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .fromTo(
          this.line17,
          1.2,
          { drawSVG: "0% 0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .fromTo(
          this.line18,
          1.2,
          { drawSVG: "0%" },
          { drawSVG: "0% 100%", ease: Power0.easeOut },
          "0"
        )
        .delay(1);

      setTimeout(() => {
        TweenMax.set(this.logo, { autoAplha: 1 });
      }, 2200);
    }
  }

  componentDidUpdate() {
    const { videoIsReady } = this.props;
    if (videoIsReady) {
      this.logoTL.play();
      this.tl.play();
    }
  }
  render() {
    return (
      <div
        className={`${styles.svgContainer} ${styles.svgContainerDesktop}`}
        ref={(graphics) => (this.graphics = graphics)}
      >
        <div
          className={styles.logo}
          ref={(container) => (this.logoContainer = container)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1367 769"
            className={styles.logoSVG}
          ></svg>
        </div>
        <div className={styles.lines} ref={(lines) => (this.lines = lines)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1367 769"
            ref={(logo) => (this.logo = logo)}
          >
            <clipPath id="intro-logoadesktop">
              <rect
                className={styles.a}
                x="-1110.22"
                y="-1328.4"
                width="35.53"
                height="155.07"
              />
            </clipPath>
            <clipPath id="intro-logobdesktop">
              <rect
                className={styles.a}
                x="-1110.22"
                y="-560.4"
                width="35.53"
                height="155.07"
              />
            </clipPath>
            <polyline
              className={styles.d}
              points="681.89 503.42 654.76 503.42 674.98 468.4 701.75 468.4 772.66 345.57 813.09 345.57 721.95 503.42 681.89 503.42"
              ref={(logo) => (this.logo1 = logo)}
            />
            <polygon
              className={styles.d}
              points="597.22 267.47 779.49 267.47 813.09 325.65 772.65 325.65 759.26 302.48 617.44 302.48 597.22 267.47"
              ref={(logo) => (this.logo2 = logo)}
            />
            <polygon
              className={styles.d}
              points="637.45 493.98 546.31 336.12 579.91 277.94 600.13 312.96 586.75 336.14 657.66 458.97 637.45 493.98"
              ref={(logo) => (this.logo3 = logo)}
            />
            <polyline
              className={styles.logoFill}
              points="681.89 503.42 654.76 503.42 674.98 468.4 701.75 468.4 772.66 345.57 813.09 345.57 721.95 503.42 681.89 503.42"
              ref={(logo) => (this.logoFill1 = logo)}
            />
            <polygon
              className={styles.logoFill}
              points="597.22 267.47 779.49 267.47 813.089 325.65 772.65 325.65 759.26 302.48 617.44 302.48 597.22 267.47"
              ref={(logo) => (this.logoFill2 = logo)}
            />
            <polygon
              className={styles.logoFill}
              points="637.45 493.98 546.31 336.12 579.91 277.94 600.13 312.96 586.75 336.14 657.66 458.97 637.45 493.98"
              ref={(logo) => (this.logoFill3 = logo)}
            />
            <g className={styles.e}>
              <line
                className={styles.f}
                x1="351.95"
                y1="-2.62"
                x2="795.92"
                y2="768.91"
                ref={(line) => (this.line1 = line)}
              />
              <line
                className={styles.f}
                x1="391.92"
                y1="-2.62"
                x2="836.5"
                y2="769.11"
                ref={(line) => (this.line2 = line)}
              />
              <line
                className={styles.f}
                x2="886.52"
                y2="769.11"
                x1="441.94"
                y1="-2.62"
                ref={(line) => (this.line3 = line)}
              />
              <line
                className={styles.f}
                x1="972.53"
                y1="-1.19"
                x2="528.75"
                y2="769.14"
                ref={(line) => (this.line5 = line)}
              />
              <line
                className={styles.f}
                x1="502.38"
                y1="768.35"
                x2="946.53"
                y2="-2.62"
                ref={(line) => (this.line6 = line)}
              />
              <line
                className={styles.f}
                x1="923.53"
                y1="-2.18"
                x2="479.32"
                y2="768.91"
                ref={(line) => (this.line7 = line)}
              />
              <line
                className={styles.f}
                x1="569.01"
                y1="769.11"
                x2="1012.77"
                y2="-1.19"
                ref={(line) => (this.line8 = line)}
              />
              <line
                className={styles.f}
                x1="741.43"
                y1="-2.18"
                x2="297.1"
                y2="769.11"
                ref={(line) => (this.line9 = line)}
              />
              <line
                className={styles.f}
                x1="337.57"
                y1="769.11"
                x2="781.9"
                y2="-2.18"
                ref={(line) => (this.line10 = line)}
              />
              <line
                className={styles.f}
                x1="1068.88"
                y1="769.55"
                x2="624.04"
                y2="-2.62"
                ref={(line) => (this.line11 = line)}
              />
              <line
                className={styles.f}
                x1="583.57"
                y1="-2.62"
                x2="1028.16"
                y2="769.11"
                ref={(line) => (this.line12 = line)}
              />
              <line
                className={styles.f}
                x1="0.5"
                y1="267.52"
                x2="1366.5"
                y2="267.52"
                ref={(line) => (this.line13 = line)}
              />
              <line
                className={styles.f}
                x1="1366.5"
                y1="302.54"
                x2="0.5"
                y2="302.54"
                ref={(line) => (this.line14 = line)}
              />
              <line
                className={styles.f}
                x1="1366.5"
                y1="345.5"
                x2="0.5"
                y2="345.5"
                ref={(line) => (this.line15 = line)}
              />
              <line
                className={styles.f}
                x1="0.5"
                y1="325.73"
                x2="1366.5"
                y2="325.73"
                ref={(line) => (this.line16 = line)}
              />
              <line
                className={styles.f}
                x1="0.5"
                y1="468.5"
                x2="1366.5"
                y2="468.5"
                ref={(line) => (this.line17 = line)}
              />
              <line
                className={styles.f}
                x1="1366.5"
                y1="503.51"
                x2="0.5"
                y2="503.51"
                ref={(line) => (this.line18 = line)}
              />
            </g>
          </svg>
        </div>
        <div
          className={`${styles.lines} ${styles.vectr}`}
          ref={(vectr) => (this.vectr = vectr)}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1367 769">
            <clipPath id="openning-logoDesktop">
              <rect
                className={styles.openningA}
                x="-1110.22"
                y="-1369.25"
                width="35.53"
                height="155.07"
              />
            </clipPath>
            <path
              className={styles.openningC}
              d="M610,346v-5h14.28l24.46,67,24-67h14.15v5l-31,82H641.11Z"
            />
            <path
              className={styles.openningC}
              d="M702,341h55.76v13H716.86v24.09h35v12.41h-35v24.34h41v13H702Z"
            />
            <path
              className={styles.openningC}
              d="M774.23,398.53V370.47c0-19.75,12.17-31.3,33.78-31.3,17,0,32,9.07,32,30.06v1.24h-14.9v-1.24c0-11.68-9.68-16.4-17.26-16.4-12.79,0-18.75,6.34-18.75,16.64v30.06c0,10.3,6,16.64,18.75,16.64,7.58,0,17.26-4.72,17.26-16.4v-1.24H840v1.24c0,21-15,30.06-32,30.06C786.4,429.83,774.23,418.28,774.23,398.53Z"
            />
            <path
              className={styles.openningC}
              d="M876.67,354.08h-25.2V341h65.32v13H891.58V428H876.67Z"
            />
            <path
              className={styles.openningC}
              d="M931.93,341h37.38c17.14,0,26.2,10.06,26.2,26.7,0,11.3-5,20.73-15.27,25.08L996.51,423v5H981.85L964,394.56H946.83V428h-14.9Zm34.65,40.85c8.69,0,13.78-4.22,13.78-13.91s-5.09-13.9-13.78-13.9H946.83v27.81Z"
            />
          </svg>
        </div>
      </div>
    );
  }
}
