/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useRef } from 'react';
import SectionTitle from '../../utils/SectionTitle';
import * as styles from './index.module.scss';
import { TweenMax } from 'gsap';
import { homeSections } from '../../utils/helperFunctions';
import MapSVG from './MapSVG';

export default function Map({
	activeSection,
	footprint,
	startTimer,
	stopTimer,
	setActiveBlock,
	activeBlock,
}) {

	const sectionIsActive = activeSection === homeSections.MAP;
	const regionCoordinatesObj = [
		{
			name: 'North America',
			x: 175,
			y: 200,
		},
		{
			name: 'Latin America',
			x: 260,
			y: 340,
		},
		{
			name: 'West Coast',
			x: 120,
			y: 230,
		},
		{
			name: 'East Coast',
			x: 255,
			y: 170,
		},
		{
			name: 'EMEA',
			x: 445,
			y: 185,
		},
		{
			name: 'APAC',
			x: 670,
			y: 245,
		},
		{
			name: 'Oceania',
			x: 770,
			y: 380,
		},
	];

	const getRegion = (highlight) =>
		regionCoordinatesObj.find((region) => region.name === highlight.region);

	const circleRadiusMap = {
		1: 40.0,
		2: 57.45,
		3: 70.71,
		4: 81.85,
		5: 91.65,
		6: 100.5,
		7: 108.63,
		8: 116.19,
		9: 123.29,
		10: 130.0,
	};

	const mapCircleRadiusBySize = (highlight) =>
		Object.keys(circleRadiusMap).find((key) => key === highlight.size);

	// Append Block Name to Highlight Circles
	const blocks = footprint.map((block) => {
		return {
			...block.node.frontmatter,
			highlights: block.node.frontmatter.highlights.map((highlight) => {
				return {
					...highlight,
					name: block.node.frontmatter.name,
					x: getRegion(highlight).x,
					y: getRegion(highlight).y,
					r: circleRadiusMap[mapCircleRadiusBySize(highlight)],
				};
			}),
		};
	});

	return (
		<section
			className={`${styles.section} relative z-10 flex flex-col justify-center xs:justify-start`}
			id="map"
		>
			<SectionTitle
				color="brand-grey"
				section="map"
				isVisible={sectionIsActive}
			>
				Footprint
			</SectionTitle>
			<div className="relative xs:mt-32">
				<div
					className={`${styles.heading} text-right ml-auto lg:m-auto lg:max-w-none lg:text-left mt-16 sm:mt-0 md:pl-24 md:pr-12`}
				>
					<h3 className="md:leading-none">
						<span className="block md:text-3xl md:inline">
							VECTR HAS A TRULY GLOBAL PORTFOLIO{' '}
						</span>
						<span className="block md:text-3xl md:inline">
							WITH FOOTPRINTS ALL OVER THE WORLD
						</span>
					</h3>
				</div>
				<div className="map-container flex justify-center xxl:mt-20 xxl:mb-10 mt-10 mb-5 md:transform md:-translate-x-1/2 md:left-1/2 md:scale-105 md:relative">
					<MapSVG>
						{blocks.map((block) =>
							block.highlights.map((highlight, i) => (
								<Circle
									key={highlight.region + highlight.numbers + i}
									region={highlight.region}
									numbers={highlight.numbers}
									color={highlight.color}
									block={highlight.name}
									activeBlock={activeBlock}
									delay={highlight.order}
									sectionIsActive={sectionIsActive}
									x={highlight.x}
									y={highlight.y}
									r={highlight.r}
								/>
							))
						)}
					</MapSVG>
				</div>
				<div className={`flex justify-center md:pl-36 md:pr-8`}>
					<WordSlider
						activeBlock={activeBlock}
						startTimer={startTimer}
						stopTimer={stopTimer}
					>
						{blocks.map((block) => (
							<WordSliderBlock
								key={block.name}
								text={block.title}
								order={block.order}
								activeBlock={activeBlock}
								setActiveBlock={setActiveBlock}
								block={block.name}
							/>
						))}
					</WordSlider>
				</div>
			</div>
		</section>
	);
}

function Circle({
	numbers,
	region,
	color,
	activeBlock,
	block,
	delay,
	sectionIsActive,
	x,
	y,
	r,
}) {
	const regionClass =
		region === 'Oceania'
			? styles.oceania
			: region === 'APAC'
			? styles.apac
			: region === 'West Coast'
			? styles.westCoast
			: region === 'East Coast'
			? styles.eastCoast
			: region === 'EMEA'
			? styles.emea
			: region === 'North America'
			? styles.northAmerica
			: region === 'Latin America'
			? styles.latinAmerica
			: '';
	const circleColor =
		color === 'red'
			? styles.red
			: color === 'blue'
			? styles.blue
			: color === 'green'
			? styles.green
			: color === 'yellow'
			? styles.yellow
			: '';

	const circleRef = useRef(null);

	useEffect(() => {
		if (sectionIsActive && activeBlock === block) {
			TweenMax.to(
				circleRef.current,
				0.3,
				{
					autoAlpha: 1,
					scale: 1,
					transformOrigin: `center center`,
					delay: delay * 0.05,
				}
			);
		} else {
			TweenMax.to(circleRef.current, 0.3, {
				autoAlpha: 0,
				scale: 0,
				transformOrigin: `center center`,
			});
		}
	});
	return (
		<g
			className={`${styles.circle} ${regionClass} absolute opacity-0`}
			ref={circleRef}
		>
			<circle
				cx={x}
				cy={y}
				r={r}
				className={`${styles.circleBg} ${circleColor} opacity-85`}
			></circle>
			<text x={x} y={y} textAnchor="middle" className={styles.textContainer}>
				<tspan x={x} textAnchor="middle" dy="0em" className={styles.numbers}>
					{numbers}
				</tspan>
				<tspan x={x} textAnchor="middle" dy="1.5em" className={styles.region}>
					{region}
				</tspan>
			</text>
		</g>
	);
}

function WordSlider({ activeBlock, children, startTimer, stopTimer }) {
	const state =
		activeBlock === 'portfolio-hq'
			? styles.portfolio
			: activeBlock === 'boots-on-ground'
			? styles.boots
			: activeBlock === 'business-reach'
			? styles.business
			: '';
	return (
		<div
			className="word-slider-container relative"
			onMouseEnter={() => stopTimer()}
			onMouseLeave={() => startTimer()}
			role="region"
		>
			<div
				className={`${styles.indicator} ${state} w-4 xxs:w-3 absolute top-1/2 left-0 -ml-10 md:-ml-8 transform -translate-y-1/2 transition-all duration-500 ease-out`}
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					xmlnsXlink="http://www.w3.org/1999/xlink"
					viewBox="0 0 18.47 21"
				>
					<defs>
						<clipPath>
							<rect x="-3.04" y="104.85" />
						</clipPath>
					</defs>
					<polygon points="5.23 0 0 3.02 0 10.5 0 17.98 5.23 21 5.23 20.98 18.45 13.52 18.47 13.53 18.47 13.51 18.47 13.51 18.47 13.51 18.47 7.49 18.47 7.49 18.47 7.49 16.64 6.46 6.1 0.49 6.1 0.51 5.23 0.01 5.23 0" />
				</svg>
			</div>
			<ul className="word-slider">{children}</ul>
		</div>
	);
}

function WordSliderBlock({ text, order, activeBlock, setActiveBlock, block }) {
	const isLastBlock = order === 3 ? '' : 'mb-4';
	const isActive = block === activeBlock ? styles.active : '';

	return (
		<li
			className={`large-text font-bold font-title ${styles.wordSliderBlock} ${isLastBlock} ${isActive}`}
			onClick={() => setActiveBlock(block)}
		>
			<button
				className={`${styles.wordSliderText} md:text-left tracking-wide transition-colors ease-out duration-500`}
			>
				{text}
			</button>
		</li>
	);
}
