import React, { Component } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { TimelineMax, Sine } from "gsap";
import { Link } from "gatsby";

import * as styles from "./index.module.scss";

export default class Article extends Component {
  constructor(props) {
    super();
    this.article = null;
    this.state = { href: "", target: "", ref: "" };
    this.tl = null;
  }
  componentDidMount() {
    const { content, link } = this.props.featured;
    if (!content) {
      this.setState({
        href: link,
        target: "_blank",
        rel: "noopener noreferrer",
      });
    } else {
      this.setState({ href: "", target: "", rel: "" });
    }
    this.tl = new TimelineMax({ paused: true })
      .fromTo(
        this.article,
        1.5,
        { autoAlpha: 0, x: -30 },
        { autoAlpha: 1, x: 0, ease: Sine.easeOut }
      )
      .delay(1);
  }
  componentDidUpdate() {
    const { isVisible } = this.props;
    if (isVisible) {
      this.tl.play();
    }
  }
  render() {
    const { featured, slug } = this.props;
    return (
      <article
        className={`${styles.featured} relative flex opacity-0`}
        ref={(article) => (this.article = article)}
      >
        {featured.internal_page ? (
          <Link to={`${slug}`}>
            <div
              className={`${styles.featuredImgWrapper} h-100 relative bg-brand-black mr-12`}
            >
              {featured.feature_image ? (
                <GatsbyImage
                  image={featured.feature_image.childImageSharp.gatsbyImageData}
                  className={`${styles.featuredImg} w-full h-full`}
                  alt=""
                  loading="eager"
                />
              ) : featured.thumbnail ? (
                <GatsbyImage
                  image={featured.thumbnail.childImageSharp.gatsbyImageData}
                  className={`${styles.featuredImg} w-full h-full`}
                  alt=""
                  loading="eager"
                />
              ) : null}
              <div className={`${styles.icon} bottom-0`}></div>
            </div>
            <div className={`${styles.textContainer} self-center`}>
              <h5
                className={`${styles.title} title uppercase tracking-wide no-underline brand-black leading-none`}
              >
                {featured.title}
              </h5>
              <p className="my-6">{featured.author}</p>
              <div
                className={`${styles.category} px-4 py-2 bg-brand-green uppercase tracking-widest brand-black`}
              >
                {featured.category.includes("vectrviews")
                  ? "vectr views"
                  : featured.category}
              </div>
            </div>
          </Link>
        ) : (
          <a href={featured.link}>
            <div
              className={`${styles.featuredImgWrapper} h-100 relative bg-brand-black mr-12`}
            >
              {featured.feature_image ? (
                <GatsbyImage
                  image={featured.feature_image.childImageSharp.gatsbyImageData}
                  className={`${styles.featuredImg} w-full h-full`}
                  alt=""
                  loading="eager"
                />
              ) : featured.thumbnail ? (
                <GatsbyImage
                  image={featured.thumbnail.childImageSharp.gatsbyImageData}
                  className={`${styles.featuredImg} w-full h-full`}
                  alt=""
                  loading="eager"
                />
              ) : null}
              <div className={`${styles.icon} bottom-0`}></div>
            </div>
            <div className={`${styles.textContainer} self-center`}>
              <h5
                className={`${styles.title} title uppercase tracking-wide no-underline brand-black leading-none`}
              >
                {featured.title}
              </h5>
              <p className="my-6">{featured.author}</p>
              <div
                className={`${styles.category} px-4 py-2 bg-brand-green uppercase tracking-widest brand-black`}
              >
                {featured.category.includes("vectrviews")
                  ? "vectr views"
                  : featured.category}
              </div>
            </div>
          </a>
        )}
      </article>
    );
  }
}
