import React, { Component } from 'react';
import * as styles from './index.module.scss';
import LeftBlock from './LeftBlock';
import Icon from '../../../images/logo/lines+plus.svg';
import VECTR from '../../../images/logo/vectr-large-logo.png';
import { homeSections, HtmlParser } from '../../utils/helperFunctions';
import Player from "@vimeo/player";

export default class Intro extends Component {
	constructor(props) {
		super();
		this.title = null;
		this.paragraph = null;
		this.invest = null;
		this.build = null;
		this.strategise = null;
		this.section = null;
		this.setTimer = null;
		this.video = null;
		this.state = {
			wordSliderHovered: false,
			activeSlide: 'invest',
		};
	}

	handleMouseEnter = () => {
		const { activeSection } = this.props;

		if (activeSection === homeSections.INTRO) {
			this.setState({ wordSliderHovered: true });
		}
	};

	handleMouseLeave = () => {
		const { activeSection } = this.props;
		if (activeSection === homeSections.INTRO) {
			this.setState({ wordSliderHovered: false });
		}
	};

	componentWillUnmount() {
		clearInterval(this.setTimer);
	}

	componentDidMount(){
		new Player(this.video, {
			id: 466111048,
			background: true,
			responsive: true,
			autoplay: true,
			muted: true,
			loop: true
		});
	}

	render() {
		const {
			setInvestActive,
			setBuildActive,
			setStrategizeActive,
			internalSlideState,
			startTimer,
			stopTimer,
			stats,
		} = this.props;
		return (
			<section
				className={`${styles.section} relative w-full h-full overflow-hidden bg-white`}
				ref={(section) => (this.section = section)}
			>
				<div className={`${styles.textContainer} flex`}>
					<div className={`${styles.leftSection} relative`}>
						<div className={styles.siteTitle}>
							<img src={VECTR} className="w-full" alt="Vectr Logo"/>
						</div>
					</div>
					<div className={`${styles.rightSection} relative`}>
						<h3
							className="mb-8 leading-none tracking-wider uppercase xs:mb-4 lg:tracking-normal"
							ref={(title) => (this.title = title)}
						>
							Vectr is a global early-stage <br className="block sm:hidden" /> Venture Studio based in Hong Kong.
						</h3>
						<p
							ref={(paragraph) => (this.paragraph = paragraph)}
							className="brand-grey-secondary"
						>
							Founded in 2013, Vectr seeks to partner with globally-minded
							entrepreneurs who possess a passion for creating disruptive
							products that connect the old and new guard. Our team strives to
							be the ideal partner for founders with an eye for growth in Asia.
						</p>
					</div>
				</div>
				<div className={`${styles.mainContainer} w-full`}>
					<LeftBlock
						internalSlideState={internalSlideState}
						sliderIsHovered={this.state.wordSliderHovered}
						activeSection={this.props.activeSection}
						setBuildActive={() => setBuildActive()}
						setInvestActive={() => setInvestActive()}
						setStrategizeActive={() => setStrategizeActive()}
						startTimer={() => startTimer()}
						stopTimer={() => stopTimer()}
					></LeftBlock>
					<div className={`${styles.rightContainer} flex`}>
						<div className={`${styles.gif} self-center`}>
							<div ref={video => this.video = video}/>
							{/* <video
								loop
								autoPlay="autoplay"
								muted
								playsInline
								className="w-full"
							>
								<source src={VideoGif} type="video/mp4" />
							</video> */}
						</div>
						<div className={`${styles.textBoxContainer} relative`}>
							{stats.map((block) => (
								<TextBlock
									key={block.node.frontmatter.title}
									block={block.node.frontmatter}
									internalSlideState={internalSlideState}
								/>
							))}
						</div>
					</div>
				</div>
				<div
					className={`${styles.icon} absolute w-12 sm:w-8 right-0 bottom-0 mr-16 mb-20 sm:mr-8 sm:mb-8 xxl:mb-32`}
				>
					<img src={Icon} alt="declarative icon"/>
				</div>
			</section>
		);
	}
}

function TextBlock({ block, internalSlideState }) {
	const type =
		block.title === 'invest'
			? styles.invest
			: block.title === 'build'
			? styles.build
			: block.title === 'strategize'
			? styles.strategize
			: '';
	const state =
		internalSlideState === block.title
			? 'opacity-100 visible'
			: 'opacity-0 invisible';
	return (
		<div
			className={`${styles.textBlock} ${type} ${state} uppercase font-title text-left w-full left-0 sm:top-0 transition-all ease-out duration-1000`}
		>
			<div className={`${styles.statistics} flex`}>
				<div>{block.statistics_first.numbers}</div>
				{block.statistics_first.description && (
					<div>
						<HtmlParser source={block.statistics_first.description} />
					</div>
				)}
			</div>
			<div className={`${styles.statistics} flex`}>
				<div>{block.statistics_second.numbers}</div>
				{block.statistics_second.description && (
					<div>
						<HtmlParser source={block.statistics_second.description} />
					</div>
				)}
			</div>
			<div className={`${styles.statistics} flex`}>
				<div>{block.statistics_third.numbers}</div>
				{block.statistics_third.description && (
					<div>
						<HtmlParser source={block.statistics_third.description} />
					</div>
				)}
			</div>
		</div>
	);
}
