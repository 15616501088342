// extracted by mini-css-extract-plugin
export var gif = "index-module--gif--9GGh2";
export var leftSection = "index-module--leftSection--NrOrg";
export var mainContainer = "index-module--mainContainer--M1Rsb";
export var rightContainer = "index-module--rightContainer--FKhPy";
export var rightSection = "index-module--rightSection--imF0D";
export var siteTitle = "index-module--siteTitle--A+NSX";
export var statistics = "index-module--statistics--QVpdd";
export var textBlock = "index-module--textBlock--D3YlE";
export var textBoxContainer = "index-module--textBoxContainer--07qnq";
export var textContainer = "index-module--textContainer--3gQZV";