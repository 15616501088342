import React, { Component } from 'react';
import { TweenMax, Power2 } from 'gsap';
import * as styles from './index.module.scss';
import Typist from 'react-typist';
import { GatsbyImage } from "gatsby-plugin-image";
import { homeSections } from '../../utils/helperFunctions';

export default class Quotation extends Component {
	constructor(props) {
		super();
		this.quote = null;
	}

	componentDidUpdate() {
		const { activeSection } = this.props;
		if (activeSection === homeSections.QUOTATION) {
			TweenMax.to(this.quote, 1.3, { autoAlpha: 1, ease: Power2.easeOut });
		}
	}

	render() {
		const { img, activeSection } = this.props;
		const config = {
			show: true,
			blink: true,
			element: '|',
			cursor: {
				hideWhenDone: true,
				hideWhenDoneDelay: 2000
			},
			avgTypingDelay: 30
		};
		return (
            <section className={styles.section}>
				<GatsbyImage image={img} className="w-full h-full" loading="eager" alt="" />
				<div
					className={`${styles.quotation} brand-white font-size-title font-body font-sembold text-left`}
					ref={quote => (this.quote = quote)}
				>
					{activeSection === homeSections.QUOTATION && (
						<Typist {...config}>
							We dedicate <b>full-time</b> Studio and Growth teams towards
							supporting our portfolio companies in <b>product development</b>{' '}
							and
							<b> commercialization</b>.
						</Typist>
					)}
				</div>
			</section>
        );
	}
}
