import React, { Component } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { TimelineMax, Sine } from "gsap";
import { Link } from "gatsby";

import * as styles from "./index.module.scss";

export default class Article extends Component {
  constructor(props) {
    super();
    this.article = null;
    this.state = { href: "", target: "", ref: "" };
    this.tl = null;
  }
  componentDidMount() {
    const { content, link } = this.props.article;
    if (!content) {
      this.setState({
        href: link,
        target: "_blank",
        rel: "noopener noreferrer",
      });
    } else {
      this.setState({ href: "", target: "", rel: "" });
    }
    this.tl = new TimelineMax({ paused: true }).fromTo(
      this.article,
      1.5,
      { autoAlpha: 0, y: 30 },
      { autoAlpha: 1, y: 0, ease: Sine.easeOut }
    );
  }
  componentDidUpdate() {
    const { isVisible, delay } = this.props;
    if (isVisible) {
      this.tl.play().delay(delay);
    }
  }

  render() {
    const { article, slug } = this.props;
    return (
      <article
        className={`${styles.article} h-full relative opacity-0`}
        ref={(article) => (this.article = article)}
      >
        {article.internal_page ? (
          <Link to={`${slug}`}>
            {article.feature_image ? (
              <GatsbyImage
                image={article.feature_image.childImageSharp.gatsbyImageData}
                className={`${styles.articleImg}`}
                loading="eager"
                alt=""
              />
            ) : article.thumbnail ? (
              <GatsbyImage
                image={article.thumbnail.childImageSharp.gatsbyImageData}
                className={`${styles.articleImg}`}
                loading="eager"
                alt=""
              />
            ) : null}
            <div className={styles.icon}></div>
            <div className={styles.articleTextContainer}>
              <h5
                className={`${styles.articleTitle} body uppercase font-bold tracking-wide leading-none font-title mt-8 md:mb-8`}
              >
                {article.title}
              </h5>
              <div
                className={`${styles.category} absolute w-full bottom-0 px-4 py-2 mt-4 bg-brand-yellow-secondary uppercase tracking-widest brand-black`}
              >
                {article.category.includes("vectrviews")
                  ? "vectr views"
                  : article.category}
              </div>
            </div>
          </Link>
        ) : (
          <a href={article.link}>
            {article.feature_image ? (
              <GatsbyImage
                image={article.feature_image.childImageSharp.gatsbyImageData}
                className={`${styles.articleImg}`}
                alt=""
                loading="eager"
              />
            ) : article.thumbnail ? (
              <GatsbyImage
                image={article.thumbnail.childImageSharp.gatsbyImageData}
                className={`${styles.articleImg}`}
                alt=""
                loading="eager"
              />
            ) : null}
            <div className={styles.icon}></div>
            <div className={styles.articleTextContainer}>
              <h5
                className={`${styles.articleTitle} body uppercase font-bold tracking-wide leading-none font-title mt-8 md:mb-8`}
              >
                {article.title}
              </h5>
              <div
                className={`${styles.category} absolute w-full bottom-0 px-4 py-2 mt-4 bg-brand-yellow-secondary uppercase tracking-widest brand-black`}
              >
                {article.category.includes("vectrviews")
                  ? "vectr views"
                  : article.category}
              </div>
            </div>
          </a>
        )}
      </article>
    );
  }
}
