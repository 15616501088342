import React, { Component } from 'react';
import { Link } from 'gatsby';
import { TimelineMax, Sine } from 'gsap';
import SectionTitle from '../../utils/SectionTitle';
import * as styles from './index.module.scss';
import ReadMoreButton from '../../utils/Button/ReadMoreButton';
import FeaturedArticle from './FeaturedArticle';
import Article from './Article';
import { homeSections } from '../../utils/helperFunctions';
export default class News extends Component {
	constructor(props) {
		super(props);

		// Ref
		this.featured = null;
		this.artcileOne = null;
		this.articleTwo = null;
		this.btn = null;
		this.state = {
			active: false,
		};
		this.tl = null;
	}

	componentDidMount() {
		this.tl = new TimelineMax({ paused: true })
			.fromTo(
				this.btn,
				1.5,
				{ autoAlpha: 0 },
				{ autoAlpha: 1, ease: Sine.easeOut }
			)
			.delay(2.5);
	}

	componentDidUpdate() {
		const { activeSection } = this.props;
		if (activeSection === homeSections.NEWS) {
			this.tl.play();
		}
	}

	render() {
		const { news, activeSection, location } = this.props;
		return (
			<section
				className={`${styles.section} bg-brand-white h-full flex sm:mt-0`}
				id="news"
			>
				<SectionTitle
					section="news"
					isVisible={activeSection === homeSections.NEWS}
					color="brand-white"
				>
					News
				</SectionTitle>
				<div className={`${styles.container} self-center w-full`}>
					<div
						className={`${styles.btnContainer} absolute opacity-0 z-10`}
						ref={(btn) => (this.btn = btn)}
					>
						<Link to="/news">
							<ReadMoreButton type="primary" color="black">
								View All
							</ReadMoreButton>
						</Link>
					</div>
					<div className={`${styles.articleContainer} md:w-full md:left-0`}>
						{news[0] ? (
							<FeaturedArticle
								featured={news[0].node.frontmatter}
								isVisible={activeSection === homeSections.NEWS}
								location={location}
								slug={news[0].node.fields.slug}
							/>
						) : null}
						<div className={`${styles.articles} flex mr-0 ml-auto mt-10`}>
							{news[1] ? (
								<Article
									article={news[1].node.frontmatter}
									slug={news[1].node.fields.slug}
									isVisible={activeSection === homeSections.NEWS}
									delay={1.5}
									location={location}
								/>
							) : null}
							{news[2] ? (
								<Article
									article={news[2].node.frontmatter}
									isVisible={activeSection === homeSections.NEWS}
									slug={news[2].node.fields.slug}
									delay={2}
									location={location}
								/>
							) : null}
						</div>
					</div>
				</div>
			</section>
		);
	}
}
