// extracted by mini-css-extract-plugin
export var active = "index-module--active--QANWf";
export var blue = "index-module--blue--wFQMX";
export var boots = "index-module--boots--P6Ijt";
export var business = "index-module--business--+amrI";
export var circle = "index-module--circle--rBF4e";
export var circleBg = "index-module--circleBg--xLP8Q";
export var green = "index-module--green---eVKp";
export var indicator = "index-module--indicator--1ZX1C";
export var logo = "index-module--logo--9qNaA";
export var map = "index-module--map--UnVtQ";
export var numbers = "index-module--numbers--AGfAg";
export var portfolio = "index-module--portfolio--hCGES";
export var red = "index-module--red--Sd2ei";
export var region = "index-module--region--BfPSh";
export var section = "index-module--section--2-VXj";
export var textContainer = "index-module--textContainer--Uu33B";
export var wordSliderBlock = "index-module--wordSliderBlock--hmZ1e";
export var wordSliderText = "index-module--wordSliderText--O-aNM";
export var yellow = "index-module--yellow--j3hL3";