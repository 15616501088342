import React from "react";
import { connect } from "react-redux";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Home from "../components/Home";

let IndexPage = ({ data, location }) => (
  <Layout location={location}>
    <SEO title="Home" />
    <Home
      images={data}
      news={data.news.edges}
      featuredNews={data.featuredNews}
      introStats={data.introstats.edges}
      footprint={data.footprint.edges}
      location={location}
    ></Home>
  </Layout>
);

const mapStateToProps = (state) => {
  return state;
};
 
export const query = graphql`query HomeImgQuery {
  quotationImg: file(name: {eq: "home-quotation"}) {
    childImageSharp {
      gatsbyImageData(quality: 50, layout: FULL_WIDTH)
    }
  }
  openingImgMobile: file(name: {eq: "home-opening-mobile"}) {
    childImageSharp {
      fluid(quality: 60, maxWidth: 1000) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  openingImg: file(name: {eq: "home-video-bg"}) {
    childImageSharp {
      fluid(quality: 60, maxWidth: 3000) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  contactImg: file(name: {eq: "home-contact"}) {
    childImageSharp {
      gatsbyImageData(quality: 50, layout: FULL_WIDTH)
    }
  }
  introstats: allMarkdownRemark(filter: {frontmatter: {type: {eq: "home-intro"}}}) {
    edges {
      node {
        frontmatter {
          title
          statistics_first {
            description
            numbers
          }
          statistics_second {
            numbers
            description
          }
          statistics_third {
            description
            numbers
          }
        }
      }
    }
  }
  footprint: allMarkdownRemark(
    filter: {frontmatter: {type: {eq: "home-footprint"}}}
    sort: {order: ASC, fields: frontmatter___order}
  ) {
    edges {
      node {
        frontmatter {
          title
          name
          order
          highlights {
            region
            size
            numbers
            color
            order
          }
        }
      }
    }
  }
  news: allMarkdownRemark(
    filter: {frontmatter: {type: {eq: "news"}, home_featured: {eq: true}}}
    sort: {fields: frontmatter___date, order: DESC}
  ) {
    edges {
      node {
        fields {
          slug
        }
        frontmatter {
          author
          category
          date
          description
          link
          sector_focus
          title
          content
          feature_image {
            childImageSharp {
              gatsbyImageData(quality: 60, layout: FULL_WIDTH)
            }
          }
          thumbnail {
            childImageSharp {
              gatsbyImageData(quality: 60, layout: FULL_WIDTH)
            }
          }
          internal_page
        }
      }
    }
  }
}
`;

export default connect(mapStateToProps)(IndexPage);
