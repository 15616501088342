import React, { Component } from 'react';
import { Link } from 'gatsby';
import { TweenMax, Sine } from 'gsap';
import ContactButton from '../../utils/Button/ContactButton';
import NextSectionButton from '../../utils/Button/NextSectionButton';
import * as styles from './index.module.scss';
import { GatsbyImage } from "gatsby-plugin-image";
import { homeSections } from '../../utils/helperFunctions';
import Footer from '../../Footer';

export default class Contact extends Component {
	constructor(props) {
		super(props);
		this.btn = null;

		// Ref
		this.title = null;
	}
	componentDidUpdate() {
		const { activeSection } = this.props;
		if (activeSection === homeSections.CONTACT) {
			TweenMax.to(this.title, 1.3, { autoAlpha: 1, ease: Sine.easeOut });
			TweenMax.to(this.btn, 1, { autoAlpha: 1, ease: Sine.easeOut });
		}
	}
	render() {
		const { backToTop, img } = this.props;
		return (
            <div className={styles.sectionContainer}>
				<section className={`section ${styles.section}`}>
					<div className="absolute top-0 left-0 w-full h-full">
						<GatsbyImage image={img} className="w-full h-full" loading="eager" alt="" />	
					</div>
					<div className={`${styles.container} relative z-10`}>
						<h2
							className={`${styles.title} hero leading-none opacity-0`}
							ref={title => (this.title = title)}
						>
							Work With Us
						</h2>
						<p className={`${styles.paragraph} mt-12 mb-16`}>
							Whether you are looking for funding, have a press inquiry, or want
							to become a community partner, we’d love to hear from you.
						</p>
						<div className={`${styles.buttonContainer} w-80`}>
							<Link to="/contact">
								<ContactButton/>
							</Link>
						</div>
					</div>
					<div className={`${styles.btnWrapper} z-10`} ref={btn => (this.btn = btn)}>
						<NextSectionButton
							type="up"
							textColor="white"
							method={() => backToTop()}
						>
							Back To Top
						</NextSectionButton>
					</div>
				</section>
				<div className={styles.footerContainer}>
					<Footer />
				</div>
			</div>
        );
	}
}
