import React, { useEffect, useRef } from "react";
import { TweenMax, Power2 } from "gsap";
import * as styles from "./index.module.scss";
import "./index.scss";

function SectionTitle({ children, color, section, isVisible }) {
  const titleColor = `${color}`;
  const sectionName = `${section}`;
  const title = useRef(null);
  useEffect(() => {
    if (isVisible) {
      TweenMax.to(title.current, 1, {
        autoAlpha: 1,
        ease: Power2.easeOut
      });
    }
  });
  return (
    <div
      className={`${styles.title} ${sectionName} absolute opacity-0`}
      ref={title}
    >
      <div className={styles.icon}>
        <svg
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 40.65 28.35"
        >
          <defs>
            <clipPath id="clip-path">
              <rect className="cls-1" x="-217.37" y="230.41" />
            </clipPath>
          </defs>
          <rect y="16.09" width="40.65" height="12.27" />
          <rect width="40.65" height="12.27" />
        </svg>
      </div>
      <h2 className={`${titleColor} headline uppercase leading-none`}>
        {children}
      </h2>
    </div>
  );
}

SectionTitle.defaultProps = {
  color: "white"
};

export default SectionTitle;
