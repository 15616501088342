/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from 'react';
import { TweenMax, Sine } from 'gsap';
import _ from 'lodash';
import * as styles from './index.module.scss';
import { homeSections } from '../../../utils/helperFunctions';
export default class LeftBlock extends Component {
	constructor(props) {
		super(props);

		// Ref
		this.icon = null;
		this.box = null;
		this.setTimer = null;
		this.invest = null;
		this.build = null;
		this.strategize = null;
		this.setIconPositionOffset();
		this.throttleResizeEvent = _.throttle(() => this.setIconPosition(), 500);
	}

	componentDidUpdate() {
		const { activeSection, stopTimer } = this.props;

		if (activeSection !== homeSections.INTRO) {
			stopTimer();
		}

		if (typeof window !== 'undefined') {
			window.addEventListener('resize', this.throttleResizeEvent, false);
			this.setIconPosition();
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.resetIconPosition);
	}

	changeColor = () => {
		const { internalSlideState } = this.props;

		if (internalSlideState === 'build') {
			this.box.classList.add('bg-brand-green');
			this.box.classList.remove('bg-brand-blue');
			this.box.classList.remove('bg-brand-yellow-secondary');
		}
		if (internalSlideState === 'strategize') {
			this.box.classList.add('bg-brand-yellow-secondary');
			this.box.classList.remove('bg-brand-green');
			this.box.classList.remove('bg-brand-blue');
		}
		if (internalSlideState === 'invest') {
			this.box.classList.add('bg-brand-blue');
			this.box.classList.remove('bg-brand-green');
			this.box.classList.remove('bg-brand-yellow-secondary');
		}
	};

	setIconPositionOffset = () => {
		if (typeof window !== 'undefined') {
			this.iconPositionOffset = window.matchMedia('(max-width:550px)').matches
				? 10
				: 25;
		}
	};

	setIconPosition = () => {
		const { internalSlideState } = this.props;
		if (window.matchMedia('(max-aspect-ratio:16/14)').matches) {
			this.changeColor();
			if (internalSlideState === 'build') {
				TweenMax.to(this.icon, 0.3, {
					x: this.build.offsetLeft - this.iconPositionOffset,
					yPercent: 0,
					ease: Sine.easeOut
				});
			}
			if (internalSlideState === 'strategize') {
				TweenMax.to(this.icon, 0.3, {
					x: this.strategize.offsetLeft - this.iconPositionOffset,
					yPercent: 0,
					ease: Sine.easeOut
				});
			}
			if (internalSlideState === 'invest') {
				TweenMax.to(this.icon, 0.3, {
					x: this.invest.offsetLeft - this.iconPositionOffset,
					yPercent: 0,
					ease: Sine.easeOut
				});
			}
		} else {
			this.changeColor();
			if (internalSlideState === 'build') {
				TweenMax.to(this.icon, 0.3, {
					yPercent: 35,
					x: 0,
					ease: Sine.easeOut
				});
			}
			if (internalSlideState === 'strategize') {
				TweenMax.to(this.icon, 0.3, {
					yPercent: 68,
					x: 0,
					ease: Sine.easeOut
				});
			}
			if (internalSlideState === 'invest') {
				TweenMax.to(this.icon, 0.3, {
					yPercent: 0,
					x: 0,
					ease: Sine.easeOut
				});
			}
		}
	};

	handleClickInvest = () => {
		const { setInvestActive } = this.props;
		setInvestActive();
	};
	handleClickBuild = () => {
		const { setBuildActive } = this.props;
		setBuildActive();
	};
	handleClickStrategize = () => {
		const { setStrategizeActive } = this.props;
		setStrategizeActive();
	};

	render() {
		const {
			internalSlideState,
			slideIndex,
			startTimer,
			stopTimer
		} = this.props;
		const invest =
			internalSlideState === 'invest' || slideIndex === 0 || slideIndex === 2
				? styles.active
				: null;
		const build = internalSlideState === 'build' ? styles.active : null;
		const strategize =
			internalSlideState === 'strategize' ? styles.active : null;
		return (
			<div
				className={styles.container}
				onMouseEnter={() => stopTimer()}
				onMouseLeave={() => startTimer()}
				role="region"
			>
				<div className={styles.greenBox} ref={box => (this.box = box)} />
				<div className={styles.wordSlider}>
					<div
						className={`${styles.wordSlide} ${invest} headline uppercase relative leading-snug font-bold`}
						onClick={() => this.handleClickInvest()}
						ref={invest => (this.invest = invest)}
						role="button"
						aria-label="button"
						tabIndex="0"
					>
						<span>Invest.</span>
					</div>
					<div
						className={`${styles.wordSlide} ${build} headline uppercase relative leading-snug font-bold`}
						onClick={() => this.handleClickBuild()}
						ref={build => (this.build = build)}
						role="button"
						aria-label="button"
						tabIndex="0"
					>
						<span>Build.</span>
					</div>
					<div
						className={`${styles.wordSlide} ${strategize} headline uppercase relative leading-snug font-bold`}
						onClick={() => this.handleClickStrategize()}
						ref={strategize => (this.strategize = strategize)}
						role="button"
						aria-label="button"
						tabIndex="0"
					>
						<span>Strategize.</span>
					</div>
					<div
						className={`${styles.icon} 

            `}
						ref={icon => (this.icon = icon)}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							xmlnsXlink="http://www.w3.org/1999/xlink"
							viewBox="0 0 18.47 21"
						>
							<defs>
								<clipPath>
									<rect x="-3.04" y="104.85" />
								</clipPath>
							</defs>
							<polygon points="5.23 0 0 3.02 0 10.5 0 17.98 5.23 21 5.23 20.98 18.45 13.52 18.47 13.53 18.47 13.51 18.47 13.51 18.47 13.51 18.47 7.49 18.47 7.49 18.47 7.49 16.64 6.46 6.1 0.49 6.1 0.51 5.23 0.01 5.23 0" />
						</svg>
					</div>
				</div>
			</div>
		);
	}
}
