import React, { Component } from "react";
import { TweenMax, Sine } from "gsap";
import * as styles from "./index.module.scss";
import NextSectionButton from "../../utils/Button/NextSectionButton";
import Animation from "./Animation";
import BackgroundImage from "gatsby-background-image";

export default class Openning extends Component {
  constructor(props) {
    super();
    this.btn = null;
  }
  componentDidMount() {
    TweenMax.to(this.btn, 1, { autoAlpha: 1, ease: Sine.easeOut });
  }
  render() {
    const { fullpageApi, img, mobileImg } = this.props;
    return (
      <>
        <section
          className={`${styles.section} h-full relative overflow-hidden bg-brand-black`}
        >
          <BackgroundImage
            Tag="div"
            className={styles.bg}
            fluid={mobileImg}
            backgroundColor={`#040e18`}
          />
          <Animation img={img}/>
          <div className={styles.left}>
            <svg
              className={styles.crossIcon}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 22.09 22.09"
            >
              <defs>
                <clipPath id="clip-path2">
                  <rect
                    className={styles.cls1}
                    x="186.63"
                    y="-133.79"
                    width="35.53"
                    height="155.07"
                  />
                </clipPath>
              </defs>
              <rect
                className={styles.cls2}
                x="8.81"
                width="4.47"
                height="22.09"
                transform="translate(0 22.09) rotate(-90)"
              />
              <rect
                className={styles.cls2}
                x="8.81"
                width="4.47"
                height="22.09"
              />
            </svg>
          </div>
          <div
            className="opacity-0 absolute right-0 bottom-0 mr-10 mb-20 z-1000"
            ref={btn => (this.btn = btn)}
          >
            <NextSectionButton method={() => fullpageApi()} />
          </div>
        </section>
      </>
    );
  }
}
