import React, { Component, useRef, useEffect } from "react";
import { TweenMax, Sine } from "gsap";
import * as styles from "./index.module.scss";
import ReactFullpage from "@fullpage/react-fullpage";
import Openning from "./Openning";
import Intro from "./Intro";
import Quotation from "./Quotation";
import Map from "./Map";
import Portfolio from "./Portfolio";
import News from "./News";
import Contact from "./Contact";
import SectionIndicator from "../utils/SectionIndicator";
import loadStates from "../../hocs/loadStates";
import { homeSections } from "../utils/helperFunctions";

class Home extends Component {
  constructor(props) {
    super(props);

    // Ref
    this.fullpage = null;
    this.bg = null;
    this.state = { slideIndex: 0, activeSlide: "invest", activeFootprintBlock: "portfolio-hq" };
    this.setTimer = null;
    this.setFootprintTimer = null;
  }

  componentDidMount() {
    TweenMax.set(this.bg, { autoAlpha: 0 });
  }

  goToSlide = (i) => {
    this.fullpage.fullpageApi.moveTo(i);
  };

  autoRotate = () => {
    if (this.state.activeSlide === "invest") {
      this.setBuildActive();
    } else if (this.state.activeSlide === "build") {
      this.setStrategizeActive();
    } else if (this.state.activeSlide === "strategize") {
      this.setInvestActive();
    }
  };

  setInvestActive = () => {
    this.setState({ activeSlide: "invest" });
  };

  setBuildActive = () => {
    this.setState({ activeSlide: "build" });
  };

  setStrategizeActive = () => {
    this.setState({ activeSlide: "strategize" });
  };

  startTimer = () => {
    this.setTimer = setInterval(this.autoRotate, 3000);
  };

  stopTimer = () => {
    clearInterval(this.setTimer);
  };

	setActiveFootprintBlocks = (block) => {
		this.setState({ activeFootprintBlock: block });
	};

	rotateFootprintBlocks = () => {
		const { activeFootprintBlock } = this.state;

		if (activeFootprintBlock === 'portfolio-hq') {
			this.setActiveFootprintBlocks('business-reach');
    } 
    if (activeFootprintBlock === 'business-reach') {
			this.setActiveFootprintBlocks('boots-on-ground');
		}
    if (activeFootprintBlock === 'boots-on-ground') {
			this.setActiveFootprintBlocks('portfolio-hq');
    } 
  };
  
  startFootprintTimer = () => {
    this.setFootprintTimer = setInterval(this.rotateFootprintBlocks, 3000);
  };

  stopFootprintTimer = () => {
    clearInterval(this.setFootprintTimer);
  };

  render() {
    const { featuredNews, news, location, images, footprint, introStats } = this.props;
    const { quotationImg, contactImg, openingImg, openingImgMobile } = images;

    return <>
      <SectionIndicator
        slideIndex={this.state.slideIndex}
        goToSlide={this.goToSlide}
        numOfSlides={Object.keys(homeSections).length}
      />
      <div className={styles.blackBG} ref={(bg) => (this.bg = bg)}></div>
      <WorkWithUsBtn slideIndex={this.state.slideIndex} />
      <ReactFullpage
        licenseKey={"CE798CC3-AD9741AE-BE07A1F5-C1A9DB36"}
        ref={(slider) => (this.fullpage = slider)}
        scrollingSpeed={1000}
        dragAndMove={true}
        touchSensitivity={15}
        onLeave={(origin, destination) => {
          const { modal } = this.props;
          if (modal.isOpenned) {
            return false;
          }
          if (destination.index === homeSections.INTRO) {
            this.startTimer();
          } else {
            this.stopTimer();
          }

          if (destination.index === homeSections.MAP) {
            this.startFootprintTimer();
          } else {
            this.stopFootprintTimer();
          }

          this.setState({
            slideIndex: destination.index,
          });
        }}
        render={({ state, fullpageApi }) => {
          return (
            <ReactFullpage.Wrapper>
              <div className="section">
                <Openning
                  img={openingImg.childImageSharp.fluid}
                  mobileImg={openingImgMobile.childImageSharp.fluid}
                  fullpageApi={() => fullpageApi.moveSectionDown()}
                />
              </div>
              <div className="section">
                <Intro
                  slideIndex={this.state.slideIndex}
                  sliderRef={this.fullPageSliderMobile}
                  activeSection={this.state.slideIndex}
                  internalSlideState={this.state.activeSlide}
                  setBuildActive={() => this.setBuildActive()}
                  setInvestActive={() => this.setInvestActive()}
                  setStrategizeActive={() => this.setStrategizeActive()}
                  startTimer={() => this.startTimer()}
                  stopTimer={() => this.stopTimer()}
                  stats={introStats}
                />
              </div>
              <div className="section">
                <Quotation
                  img={quotationImg.childImageSharp.gatsbyImageData}
                  activeSection={this.state.slideIndex}
                />
              </div>
              <div className="section">
                <Map
                  sliderRef={this.fullPageSliderMobile}
                  activeSection={this.state.slideIndex}
                  footprint={footprint}
                  setActiveBlock={this.setActiveFootprintBlocks}
                  activeBlock={this.state.activeFootprintBlock}
                  startTimer={() => this.startFootprintTimer()}
                  stopTimer={() => this.stopFootprintTimer()}
                />
              </div>
              <div className="section">
                <Portfolio
                  sliderRef={this.fullPageSliderMobile}
                  activeSection={this.state.slideIndex}
                />
              </div>
              <div className="section">
                <News
                  news={news}
                  featuredNews={featuredNews}
                  sliderRef={this.fullPageSliderMobile}
                  activeSection={this.state.slideIndex}
                  location={location}
                />
              </div>
              <div className="section">
                <Contact
                  img={contactImg.childImageSharp.gatsbyImageData}
                  activeSection={this.state.slideIndex}
                  backToTop={() => fullpageApi.moveTo(1)}
                />
              </div>
            </ReactFullpage.Wrapper>
          );
        }}
      />
    </>;
  }
}

export default loadStates(Home);

function WorkWithUsBtn({ slideIndex }) {
  const btn = useRef();
  useEffect(() => {
    if (slideIndex === 6) {
      TweenMax.to(btn.current, 1, { autoAlpha: 0, ease: Sine.easeOut });
    }
    if (slideIndex !== 6 && slideIndex !== 0) {
      TweenMax.to(btn.current, 1, { autoAlpha: 1, ease: Sine.easeOut });
    }
    if (slideIndex === 0) {
      setTimeout(() => {
        if (slideIndex === 0) {
          TweenMax.to(btn.current, 1, { autoAlpha: 1, ease: Sine.easeOut });
        }
      }, 8200);
    }
  });
  return (
    <div className={styles.WorkWithUs} ref={btn}>
      <svg
        className={styles.contactIcon}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 71.05 67.77"
      >
        <defs>
          <clipPath id="clip-path-wwu">
            <rect className={styles.cls1} x="213.35" y="-218.93" />
          </clipPath>
        </defs>
        <path
          className={styles.cls2}
          d="M60.63,61.08H20.38C17.52,61.08,0,67.77,0,67.77s10-14.24,10-17.1V10.41A10.44,10.44,0,0,1,20.38,0H60.63A10.44,10.44,0,0,1,71,10.41V50.67A10.44,10.44,0,0,1,60.63,61.08Z"
        />
        <path
          className={styles.cls3}
          d="M24.56,20.47l-2-8h1.1l1,4.06c.24,1,.45,2,.6,2.78h0c.13-.8.38-1.75.66-2.79l1.07-4.05H28l1,4.08c.23,1,.44,1.91.56,2.75h0c.17-.88.39-1.78.64-2.78l1.06-4.05h1.06l-2.28,8H29l-1-4.18a24.17,24.17,0,0,1-.52-2.62h0a25.1,25.1,0,0,1-.62,2.62l-1.14,4.18Z"
        />
        <path
          className={styles.cls3}
          d="M41.39,16.37c0,2.76-1.68,4.23-3.73,4.23S34,19,34,16.53s1.58-4.22,3.73-4.22S41.39,14,41.39,16.37Zm-6.23.13c0,1.72.93,3.25,2.56,3.25s2.57-1.51,2.57-3.34c0-1.6-.83-3.27-2.56-3.27S35.16,14.74,35.16,16.5Z"
        />
        <path
          className={styles.cls3}
          d="M43.93,12.55a10.81,10.81,0,0,1,2-.17,3.28,3.28,0,0,1,2.32.66,2,2,0,0,1,.63,1.53,2.11,2.11,0,0,1-1.51,2v0a2,2,0,0,1,1.18,1.62,12.78,12.78,0,0,0,.62,2.2H48.09a10.4,10.4,0,0,1-.54-1.92c-.24-1.11-.67-1.53-1.61-1.56H45v3.48h-1Zm1,3.66H46c1.11,0,1.81-.61,1.81-1.53s-.75-1.49-1.85-1.5a4.27,4.27,0,0,0-1,.1Z"
        />
        <path
          className={styles.cls3}
          d="M51.53,12.44h1v3.87h0c.21-.31.43-.6.63-.86l2.45-3H57l-2.91,3.41,3.13,4.62H56l-2.65-3.94-.76.88v3.06h-1Z"
        />
        <path
          className={styles.cls3}
          d="M24.56,34.77l-2-8h1.1l1,4.06c.24,1,.45,2,.6,2.78h0c.13-.8.38-1.75.66-2.79l1.07-4.05H28l1,4.08c.23,1,.44,1.91.56,2.75h0c.17-.88.39-1.78.64-2.78l1.06-4.05h1.06l-2.28,8H29l-1-4.18A24.17,24.17,0,0,1,27.43,28h0a25.1,25.1,0,0,1-.62,2.62l-1.14,4.18Z"
        />
        <path className={styles.cls3} d="M35.56,26.74v8h-1v-8Z" />
        <path
          className={styles.cls3}
          d="M40.09,27.62H37.65v-.88h5.95v.88H41.14v7.15h-1Z"
        />
        <path
          className={styles.cls3}
          d="M46.72,26.74V30.1H50.6V26.74h1v8h-1V31H46.72v3.77h-1v-8Z"
        />
        <path
          className={styles.cls3}
          d="M24.29,41V45.8c0,1.8.8,2.56,1.87,2.56s2-.79,2-2.56V41h1v4.68c0,2.47-1.3,3.48-3,3.48s-2.88-.94-2.88-3.43V41Z"
        />
        <path
          className={styles.cls3}
          d="M32,47.81a3.67,3.67,0,0,0,1.86.52c1.06,0,1.68-.56,1.68-1.37s-.43-1.18-1.51-1.6c-1.31-.46-2.12-1.14-2.12-2.28a2.31,2.31,0,0,1,2.6-2.18,3.65,3.65,0,0,1,1.78.39l-.29.85a3.2,3.2,0,0,0-1.53-.38c-1.1,0-1.51.66-1.51,1.2,0,.75.49,1.12,1.6,1.55,1.36.52,2,1.18,2,2.36s-.92,2.31-2.81,2.31a4.15,4.15,0,0,1-2-.51Z"
        />
      </svg>
    </div>
  );
}
