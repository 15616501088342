import React, { useRef } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import Slider from "react-slick";

import * as styles from "./index.module.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Arrow from "../Arrow";

export default function Sliders() {
  const sliderNav = useRef();
  const sliderBg = useRef();
  const data = useStaticQuery(graphql`
    query HomePortfolioLogoQuery {
      allMarkdownRemark(
        filter: {
          frontmatter: { type: { eq: "home-project" }, featured: { eq: true } }
        }
        sort: { fields: frontmatter___order, order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              link
              title
              background {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
              logo {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  `);
  const settingsSlider = {
    infinite: true,
    vertical: true,
    centerMode: true,
    slidesToShow: 1,
    verticalSwiping: true,
    draggable: false,
    centerPadding: "0px",
    nextArrow: <Arrow id="arrow-up" type="arrow-up--desktop" />,
    prevArrow: <Arrow id="arrow-down" type="arrow-down--desktop" />,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          verticalSwiping: false,
          vertical: false,
        },
      },
    ],
  };

  const settingsSliderBg = {
    infinite: true,
    vertical: true,
    centerMode: true,
    slidesToShow: 1,
    draggable: false,
    verticalSwiping: true,
    centerPadding: "0px",
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          verticalSwiping: false,
          vertical: false,
        },
      },
    ],
  };

  return (
    <>
      <div className={`${styles.sliderNav} sliderNav`}>
        <Slider {...settingsSlider} ref={sliderNav} asNavFor={sliderBg.current}>
          {data.allMarkdownRemark.edges.map((project, i) => (
            <div className={`${styles.sliderNavSlide} h-auto`} key={i}>
              <a
                href={project.node.frontmatter.link}
                target="_blank"
                rel="noreferrer"
              >
                <GatsbyImage
                  image={
                    project.node.frontmatter.logo.childImageSharp
                      .gatsbyImageData
                  }
                  alt=""
                />
              </a>
            </div>
          ))}
        </Slider>
      </div>

      <div
        className={`${styles.slider} portfolio-slider absolute overflow-hidden h-full top-0 right-0`}
      >
        <Slider
          {...settingsSliderBg}
          ref={sliderBg}
          asNavFor={sliderNav.current}
        >
          {data.allMarkdownRemark.edges.map((project, i) => (
            <div className={styles.slide} key={i}>
              <a
                href={project.node.frontmatter.link}
                target="_blank"
                rel="noreferrer"
              >
                <GatsbyImage
                  image={
                    project.node.frontmatter.background.childImageSharp
                      .gatsbyImageData
                  }
                  className="w-full h-full"
                  loading="eager"
                  alt=""
                />
              </a>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
}
